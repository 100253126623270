(function ($) {
  $(".slider").slick({
  
    // normal options...
    slidesToShow: 6,
    infinite: true,
    swipeToSlide: true,
  
    // the magic
    responsive: [
      {
        
  
        breakpoint: 2100,
        settings: {
          slidesToShow: 5.5,
        }
      },
      {
        
  
        breakpoint: 1800,
        settings: {
          slidesToShow: 4.5,
        }
      },
      {
  
        breakpoint: 1600,
        settings: {
          slidesToShow: 3.5,
        }
  
      },
      {
  
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.5,
        }
  
      },
      {
  
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
        }
  
      },
      {
  
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.5,
        }
  
      },
      {
  
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
  
      },
      {
  
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
  
      }, 
       
      {
  
        breakpoint: 300,
        settings: "unslick" // destroys slick
  
      }]
  });

  $(".banner-slider").slick({
  
    // normal options...
    slidesToShow: 1,
    arrows : false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    appendDots: $(".custom-paging"),
    dots: true,
   
  });

})(jQuery);
