(function ($) {
    var billingUserTypes = $("[name='billing_user_type']"),
        billingUserType = $("[name='billing_user_type']:checked").val();
    detectUserBilling(billingUserType);

    billingUserTypes.on('click', function(){
        billingUserType = $("[name='billing_user_type']:checked").val();
        detectUserBilling(billingUserType);
    }) 

    function detectUserBilling(billingUserType) {
        if (billingUserType != 'company') {
            $('.js-company-fields').hide();
        }
        else {
            $('.js-company-fields').show();
        }
    }
})(jQuery);