(function ($) {
$(document).ready(function() {
    // pobierz elementy z DOM
    const selectTaste = $(".select-taste");
     
    if(selectTaste.parent().find('.button_taste').val() == '')
    {
        selectTaste.parent().find('.button_taste').prop('disabled', true);
        selectTaste.parent().find('.button_taste').addClass('disabled');

    }

    selectTaste.change(function(event) {
        event.preventDefault();
        event.stopPropagation();
        const submitButton = $(this).parent().find('.button_taste');
        submitButton.prop('disabled', false);
        submitButton.removeClass('disabled');
        submitButton.val($(this).val());
    });

    jQuery('.js-product-variation-select-price').change(function(e) {
   
        e.preventDefault();
    
      var selectedVariantID = jQuery('option:selected', this).val();
      if (selectedVariantID ) {
        $(this).parent().find('.single_add_to_cart_button').removeClass('disabled');
        $(this).parent().find('.single_add_to_cart_button').prop('disabled', false);;
      }
      
    });
    
});


})(jQuery);