(function ($) {
    /**
     * shrink header on scroll
     */

    var $mainHeader = $('#main-header');
    var $textColorMenu = $mainHeader.find('.main-menu');
    var $banner = $('.product-banner');
    var $logo = $mainHeader.find('.main-header__logo');
    var $sm = $('.social-media-menu__text');
    var $colo = $banner.data('color');

    scrollInit();

    $(window).scroll(function() {
        scrollInit();
    });

    function scrollInit() {
        var scroll = $(window).scrollTop();

        if (scroll >= 20) { 
            $mainHeader.addClass('main-header--scroll');
            if($colo == "#e7ebee")
            {
                $textColorMenu.removeClass('main-header--color-text');
                $logo.removeClass('main-header--color-logo');
               



            }   

        }
        else { 
            $mainHeader.removeClass('main-header--scroll'); 
            if($colo == "#e7ebee")
                {

                    $textColorMenu.addClass('main-header--color-text');
                    $logo.addClass('main-header--color-logo');
                    $sm.addClass('social-media-menu__text--color');


                }

        }
    }

})(jQuery);