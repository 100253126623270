(function ($) {

jQuery(document).ready(function() {
  // Przechwytywanie zmiany wariantu i aktualizacja ceny
  
  
  jQuery('.js-product-variation-select-price').change(function(e) {
   
      e.preventDefault();
  
  
    var selectedVariantPrice = jQuery('option:selected', this).attr('data-price');
    var selectedVariantPriceSale = jQuery('option:selected', this).attr('data-regular');
    var wrapperOldPrice = jQuery('.js-product-price-old'); //karta produktowa
    var wrapperOldPriceSG = $(this).parent().find('.js-product-price-old__wrapper'); //ogólny widok

    wrapperOldPrice.show();
    wrapperOldPriceSG.show();
  
    if(selectedVariantPrice == selectedVariantPriceSale)
    {
        wrapperOldPrice.hide();
        wrapperOldPriceSG.hide();
    }

    //ogólny widok
    $(this).parent().find('.js-product-price-view').text(selectedVariantPrice.replace('.', ","));
    $(this).parent().find('.js-product-price-sale-view-cart-product-set').text(selectedVariantPriceSale.replace('.', ","));


    //na karcie produktowej
    if($('.js-product-price-view-cart-product').length)
    {
      $('.js-product-price-view-cart-product').text(selectedVariantPrice.replace('.', ","));
    }
    if($('.js-product-price-sale-view-cart-product').length)
    {
      $('.js-product-price-sale-view-cart-product').text(selectedVariantPriceSale.replace('.', ","));
    }
   
  });
});

})(jQuery);