(function ($) {
    jQuery(document).ready(function() {

$( '.home-banner-button' ).on( 'click', function(e){

  var href = $(this).data( 'link' );

  $( 'html, body' ).animate({
    scrollTop:  $(href).offset().top
  }, 900, 'swing' );
  e.preventDefault();
});
  });
})(jQuery);
